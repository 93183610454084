/* Basic CSS Reset */
html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: "Red Hat Display", sans-serif;
}

ol,
ul {
  list-style: none;
}
/* end Basic CSS Reset */

html,
body,
#root {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

*:focus {
  outline: 0;
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
textarea:focus,
input:focus {
  outline: none;
}
input:focus {
  outline: none;
}

/* hide mapbox gl message */
.mapboxgl-ctrl-attrib-inner {
  display: none;
}
